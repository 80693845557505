import { useHistory } from 'react-router';
import useFlow from './useFlow';

export default () => {
    const history = useHistory();

    const { isVerify, redirect } = useFlow();

    return (link: string) => () => {
        if (isVerify) {
            history.push(link);
            return;
        }

        redirect(link);
    };
};
