import useFlow from './useFlow';

export default () => {
    const { isVerify, redirect } = useFlow();

    return (callback: Function, contentUrl?: string) => () => {
        if (isVerify) {
            callback();
            return;
        }

        redirect(contentUrl);
    };
};
