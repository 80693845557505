export const firebaseProjectToken = '27d37d62-41cd-4251-9b76-47f5543bbe23';

// eslint-disable-next-line max-len
export const firebaseVapidKey = 'BAd3Y-taS6x18GlubKeCi6GuDkq5v_WsMUpihNOa5jxI9WL0NV6FQnTxJydF5TLUBW4xZGVmBUEJhz42QU9TL5A';

export const firebaseConfig = {
    apiKey: 'AIzaSyATquY9MRNW6RsuYrSbboe8oXqRqoAa1eQ',
    authDomain: 'smartbooks-rb-mts.firebaseapp.com',
    projectId: 'smartbooks-rb-mts',
    storageBucket: 'smartbooks-rb-mts.appspot.com',
    messagingSenderId: '685623684872',
    appId: '1:685623684872:web:1f6f78aba200d394cf86bd',
};
