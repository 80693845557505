import { createGlobalStyle } from 'styled-components';
import { ITheme } from '../constants/themes';
import Breakpoints from '../constants/breakpoints';

export default createGlobalStyle<{ theme: ITheme }>`
    #root {
        position: relative;
        z-index: 1;
    }
    * {
        ::-webkit-scrollbar{
            width: ${({ theme }) => theme.width.thumb};
            height: ${({ theme }) => theme.height.thumb};
        }
        ::-webkit-scrollbar-thumb{
            background: ${({ theme }) => theme.background.thumb};
        }
    }
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }
    *:focus {
        outline: none !important;
    }
    *:active {
        outline:none !important;
    }
    button{
        cursor: pointer;
        border-radius: 0;
        padding: 0;
        margin: 0;
        border: none;
        -webkit-appearance: none;
        &:focus {
            outline: none !important;
        }
    }
    audio, video {
        display: block;
    }
    img {
        border: none;
    }
    * > img{
        max-width: 100%;
    }
    body {
        font-family: ${({ theme }) => theme.fontFamily.sans};
        background: ${({ theme }) => theme.background.primary};
        color: ${({ theme }) => theme.color.primary};
        font-size: ${({ theme }) => theme.fontSize.primary};
        line-height: 1.2;
        overflow-x: hidden;
        overflow-y: scroll;
        min-width: 320px;
    }
    a {
        text-decoration: none;
        *,
        &:hover,
        &:active,
        &:visited {
            color: ${({ theme }) => theme.color.secondary};
        }
    }
    
    .display-enter {
        opacity: 0;
    }
    .display-enter-active {
        opacity: 1;
        transition: opacity 200ms;
    }
    .display-exit {
        opacity: 1;
    }
    .display-exit-active {
        opacity: 0;
        transition: opacity 200ms;
    }
    
    .booksPage__infinity {
        margin: auto;
        @media only screen and (${Breakpoints.LAPTOP}) {
            margin-top: 80px;
        }
    }
    
    .PrivacyPolicy-P_adoption {
        color: #000;
    }
`;
