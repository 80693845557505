import React, { MouseEvent } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as PlayIconSVG } from '../../media/icons/promo-icon.svg';
import { ReactComponent as PlayIconCircleSVG } from '../../media/icons/promo-play.svg';
import Breakpoints from '../../themes/constants/breakpoints';

const flash = keyframes`
  0% {
    left: -150%;
  }
  20% {
    left: 100%
  }
  100% {
    left: 150%
  }
`;

const Container = styled.div<{ image: string }>`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  overflow: hidden;
  height: 90px;
  border-radius: 18px;
  background-image: ${({ image }) => `url("${image}")`};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 17%;
  cursor: pointer;
  padding: 0 8px;
  @media only screen and (${Breakpoints.TABLET}) {
    height: 125px;
    padding: 0 50px;
  }
  @media only screen and (${Breakpoints.LAPTOP}) {
    height: 200px;
    padding: 0 100px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%) skewX(-30deg);
    display: block;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(90deg, rgba(0,0,0,0), hsla(0,0%,100%,.3) 90%, rgba(0,0,0,0));
    animation: ${flash} 5s ease-in-out infinite;
    z-index: 100;
    padding: 0 10px;
  }
`;

const Text = styled.div<{ leftSideImage?: boolean }>`
  font-size: 20px;
  line-height: 26px;
  font-weight: 900;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  text-transform: uppercase;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ leftSideImage }) => leftSideImage && css`
    display: inline;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    & svg {
      margin-left: 10px;
    }
  `}
  @media only screen and (${Breakpoints.TABLET}) {
    font-size: 26px;
    line-height: 29px;
  }
`;

const RightSideImage = styled(PlayIconCircleSVG)`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

export type IPromoBannerProps = {
    image: string;
    text: string;
    rightSideText?: string;
    rightSideImage?: boolean;
    leftSideImage?: boolean;
};

const link = 'http://m.funzone.by/subslp/flow/m_premium?lpid=25';

export const PromoBannerItem = (props: IPromoBannerProps) => {
    const {
        image,
        text,
        rightSideText,
        rightSideImage,
        leftSideImage,
    } = props;

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        window.location.replace(link);
    };

    return (
        <Container image={image} onClick={handleClick}>
            <Text leftSideImage={leftSideImage}>
                {text}
                {leftSideImage && <PlayIconSVG />}
            </Text>
            {rightSideImage && <RightSideImage />}
            {rightSideText
        && <Text>{rightSideText}</Text>}
        </Container>
    );
};
