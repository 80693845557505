import { useVerifyQuery } from 'generated/graphql';

interface IUseFlow {
    isVerify: boolean
    loading: boolean
    redirect: (contentUrl?: string) => void
}

function useFlow(): IUseFlow {
    const { data, loading } = useVerifyQuery();

    const isVerify = data?.point?.verify || false;

    const flowURL = data?.point?.flowURL || 'https://google.com';
    const flowMark = flowURL.includes('?') ? '&' : '?';
    const contentUrlValue = encodeURIComponent(window.location.pathname) + encodeURIComponent(window.location.search);
    const sourceServiceStr = 'source=service';
    const sourceService = flowURL.includes(sourceServiceStr) ? '' : `&${sourceServiceStr}`;
    const flow = (contentUrl?: string) => {
        const cUrl = contentUrl ? encodeURIComponent(contentUrl) : contentUrlValue;
        return `${flowURL}${flowMark}content_url=${cUrl}${sourceService}`;
    };

    const redirect = (contentUrl?: string) => {
        window.location.href = flow(contentUrl);
    };

    return {
        isVerify,
        loading,
        redirect,
    };
}

export default useFlow;
