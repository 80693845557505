import React, {
    ChangeEvent, FormEvent, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash.isempty';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useMediaQuery } from 'react-responsive';
import { Inner, TextField } from 'components';
import useDebounce from '../../hooks/useDebounce';
import RoutePath from '../../routes/constants/routes';
import useFlow from '../../hooks/useFlow';
import Breakpoints from '../../themes/constants/breakpoints';
import { filterContentByCompilationAndBookType } from '../../helpers/filterContentByCompilationAndBookType';
import {
    Order,
    SearchContentFragment,
    SearchField,
    useSearchShortContentLazyQuery,
} from '../../generated/graphql';

import { ReactComponent as XIcon } from '../../media/icons/x.svg';
import { ReactComponent as ArrowRightIcon } from '../../media/icons/arrow-right.svg';

import searchIcon from '../../media/icons/search.svg';
import searchRedIcon from '../../media/icons/search-red.svg';

// Styles
const Button = styled.button<{ isActive?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    height: inherit;
    min-width: 48px;
    z-index: 1000;
    & > svg{
        width: 24px;
        height: 24px;
        max-width: 24px;
        max-height: 24px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        & > svg{
            width: 32px;
            height: 32px;
            max-width: 32px;
            max-height: 32px;
        }
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        min-width: 24px;
        margin-left: 26px;
        & > svg{
            width: 24px;
            height: 24px;
            max-width: 24px;
            max-height: 24px;
        }
    }
`;
const SearchBarMobile = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-right: 8px;
    height: inherit;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.background.header};
    z-index: 1000;
    @media only screen and (${Breakpoints.TABLET}) {
        padding: 0 20px;
    }
`;
const SearchBarDesktop = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.height.header.desktop};
    left: 50%;
    transform: translateX(-50%);
    width: 540px;
    background: ${({ theme }) => theme.background.card};
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 20px 20px;
    padding: 20px 0 15px 0;
    z-index: 1000;
`;
const SearchBarMobileInner = styled(Inner)`
    height: inherit;
    justify-content: center;
`;
const Form = styled('form')`
    display: flex;
    flex-flow: row nowrap;
    height: inherit;
    align-items: center;
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        padding: 0 17px 0 30px;
    }
`;
const SearchDropdownMobile = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.height.header.primary};
    left: 0;
    right: 0;
    display: flex;
    background: ${({ theme }) => theme.background.header};
    z-index: 1000;
    padding: 10px 0 15px;
    @media only screen and (${Breakpoints.TABLET}) {
        top: ${({ theme }) => theme.height.header.tablet};
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        top: ${({ theme }) => theme.height.header.desktop};
    }
`;
const DropdownResult = styled.div`
    display: flex;
    flex-flow: column nowrap;
    max-height: 500px;
    overflow-y: auto;
`;
const DropdownResultItem = styled.div`
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.primary};
    padding: 10px 19px;
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        padding: 10px 30px;
    }
`;
const SearchFieldDiv = styled.div`
    padding: 0 20px;
    flex-grow: 1;
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        padding: 0 0 10px;
    }
`;

const SearchIcon = styled.img`
    width: 24px;
    height: 24px;
`;

// Interfaces
interface IHeaderSearch {
    open: boolean
    setOpen: (open: boolean) => void
}

// Component
export const HeaderSearch = (props: IHeaderSearch) => {
    const {
        open,
        setOpen,
    } = props;

    const history = useHistory();

    const { isVerify, redirect } = useFlow();

    const isSearchPage = history?.location.pathname === RoutePath.SEARCH_PATH;

    const isLaptopL = useMediaQuery({ query: `(${Breakpoints.LAPTOP_L})` });

    const search = history?.location.search || '';
    const searchObj = queryString.parse(search);
    const searchQuery = searchObj?.search || '';

    const [formState, setFormState] = useState<{ search: string }>({ search: `${searchQuery}` });
    const [searchTerm, setSearchTerm] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchResultsForDrop, setSearchResultsForDrop] = useState<SearchContentFragment[] | null>(null);

    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    const [searchShortContentByName] = useSearchShortContentLazyQuery({
        onCompleted: (res) => {
            const filteredContent = res?.point?.service?.searchByField
                ? filterContentByCompilationAndBookType(res?.point?.service?.searchByField, '')
                : null;
            setSearchResultsForDrop(filteredContent);
        },
    });

    useEffect(() => {
        if (debouncedSearchTerm.length > 1 && !isSearchPage) {
            searchShortContentByName({
                variables: {
                    query: debouncedSearchTerm,
                    fields: [SearchField.Name, SearchField.Creator],
                    sort: {
                        paramName: 'name',
                        order: Order.Asc,
                    },
                },
            })
                .catch(() => {});
        }
    }, [debouncedSearchTerm]);

    const handleOpen = () => {
        if (!(isLaptopL && isSearchPage)) setOpen(true);
    };
    const handleClose = () => {
        if (!(isLaptopL && isSearchPage)) setOpen(false);
    };

    const handleToggleMenu = () => {
        if (isVerify) {
            setDropdownOpen(true);
            handleOpen();
            return;
        }

        redirect();
    };
    const handleCloseMenu = () => {
        const appendedSearchObj = {
            ...searchObj,
            search: '',
        };
        history?.push({
            search: queryString.stringify(appendedSearchObj),
        });

        setFormState({
            ...formState,
            search: '',
        });

        setSearchResultsForDrop(null);

        if (!(isSearchPage && isLaptopL)) {
            handleClose();
        }
    };
    const handleCloseSearchDropdown = () => setDropdownOpen(false);
    const handleChangeSearch = (ev: ChangeEvent<HTMLInputElement>) => {
        const searchValue = ev.target.value || '';

        setFormState({
            ...formState,
            search: searchValue,
        });

        setSearchTerm('');

        setTimeout(() => setSearchTerm(searchValue));
    };
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const appendedSearchObj = {
            search: formState.search,
        };

        history.push({
            pathname: RoutePath.SEARCH_PATH,
            search: queryString.stringify(appendedSearchObj),
        });

        setDropdownOpen(false);
    };

    const searchBtnRef = useRef<HTMLButtonElement>(null);
    const searchDropdownRef = useRef<HTMLDivElement>(null);
    const searchBarMobileRef = useRef<HTMLDivElement>(null);
    const searchBarDesktopRef = useRef<HTMLDivElement>(null);
    const showDropDown = dropdownOpen && open && !isSearchPage;

    useEffect(() => {
        const appendedSearchObj = {
            ...searchObj,
            search: debouncedSearchTerm || '',
        };
        if (open && debouncedSearchTerm) {
            history?.push({
                search: queryString.stringify(appendedSearchObj),
            });
        }
        setDropdownOpen(!!debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (searchBtnRef.current && searchDropdownRef.current && searchBarMobileRef.current) {
                if (
                    !searchBtnRef.current.contains(event.target as Node)
                    && !searchDropdownRef.current.contains(event.target as Node)
                    && !searchBarMobileRef.current.contains(event.target as Node)
                ) {
                    handleCloseSearchDropdown();
                }
            }

            if (searchBtnRef.current && searchBarDesktopRef.current) {
                if (
                    !searchBtnRef.current.contains(event.target as Node)
                    && !searchBarDesktopRef.current.contains(event.target as Node)
                ) {
                    handleCloseSearchDropdown();
                    if (isSearchPage && isLaptopL) return;
                    handleCloseMenu();
                }
            }
        };
        window.addEventListener('click', listener);
        return () => window.removeEventListener('click', listener);
    }, [open]);

    const searchDropdownResults = (
        <DropdownResult>
            {searchResultsForDrop
                ? searchResultsForDrop.map(({ id, name }: SearchContentFragment) => (
                    <DropdownResultItem
                        onClick={() => {
                            history.push(`/books/${id}`);
                            setDropdownOpen(false);
                            handleClose();
                        }}
                        key={id}
                    >
                        {name}
                    </DropdownResultItem>
                )) : null}
        </DropdownResult>
    );

    const searchForm = (
        <Form onSubmit={handleSubmit}>
            <SearchFieldDiv>
                <TextField
                    name="search"
                    label="Поиск"
                    autoFocus
                    autoComplete="off"
                    value={formState.search}
                    onChange={handleChangeSearch}
                />
            </SearchFieldDiv>
            <Button
                type="submit"
            >
                <ArrowRightIcon />
            </Button>
            <Button
                onClick={handleCloseMenu}
                type="button"
            >
                <XIcon />
            </Button>
        </Form>
    );

    const searchBarMobile = (
        <SearchBarMobile
            ref={searchBarMobileRef}
        >
            <SearchBarMobileInner
                withoutPaddings
            >
                {searchForm}
            </SearchBarMobileInner>
        </SearchBarMobile>
    );

    const searchBarDesktop = (
        <SearchBarDesktop
            ref={searchBarDesktopRef}
        >
            {searchForm}
            {(!isSearchPage && showDropDown) && searchDropdownResults}
        </SearchBarDesktop>
    );

    const dropdownMobile = (
        <SearchDropdownMobile
            ref={searchDropdownRef}
        >
            <Inner withoutPaddings>
                {searchDropdownResults}
            </Inner>
        </SearchDropdownMobile>
    );

    return (
        <>
            <Button
                onClick={handleToggleMenu}
                ref={searchBtnRef}
            >
                <SearchIcon src={(open || isSearchPage) && isLaptopL ? searchRedIcon : searchIcon} />
            </Button>
            {open && (isLaptopL ? searchBarDesktop : searchBarMobile)}
            {(showDropDown && !isLaptopL && !isEmpty(searchResultsForDrop)) && dropdownMobile}
        </>
    );
};
