enum APILinks {
    PRIVACY_FOOTER = '/text/common-text-service/privacyfooter/1',
    OFERTA = '/text/rb-mts-smartbooks/oferta/1',
    FOOTER = '/text/rb-mts-smartbooks/footer/1',
    FOOTER_UNDER = '/text/rb-mts-smartbooks/footer-under/1',
    SUPPORT_CONTACT = '/text/rb-mts-smartbooks/header-support/1',
    FIREBASE_TOKEN = '/firebase/token',
}

export default APILinks;
